import { Row, Col, Container, Card, Navbar, Nav } from "react-bootstrap";

import Image0 from "../assets/0.jpg";
import Image1 from "../assets/1.jpg";
import Image2 from "../assets/2.jpg";
import Image3 from "../assets/3.jpg";
import Image4 from "../assets/4.jpg";
import Image5 from "../assets/5.jpg";
import Image6 from "../assets/6.jpg";
import Image7 from "../assets/7.jpg";
import Image8 from "../assets/8.jpg";
import Image9 from "../assets/9.jpg";
import Image10 from "../assets/10.jpg";

function Home() {
  return (
    <>
      <Navbar sticky="top" expand="md">
        <Container fluid>
          <Navbar.Brand>
            <h1 style={{ color: "#000" }}>BRAID</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="ml-auto">
              <Nav.Link className="text-center">
                <h5
                  href="#"
                  onClick={() =>
                    window.open("https://t.me/goldenbraid", "_blank")
                  }
                >
                  TELEGRAM
                </h5>
              </Nav.Link>
              <Nav.Link className="text-center">
                <h5
                  href="#"
                  onClick={() =>
                    window.open("https://x.com/goldenbraidAI", "_blank")
                  }
                >
                  TWITTER
                </h5>
              </Nav.Link>
              <Nav.Link className="text-center">
                <h5
                  href="#"
                  onClick={() =>
                    window.open(
                      "https://dexscreener.com/solana/39yCqErdfzsYiro78fiGSeLCF92C3KUXUudpWqxkpump",
                      "_blank"
                    )
                  }
                >
                  CHART
                </h5>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid className="text-center">
        {/* <h1 className="mt-5" style={{fontSize: 80}}>$BRAID</h1> */}

        <Row className="justify-content-center align-items-center d-flex mt-5">
          <Col xs={12}>
            <h1 style={{ fontSize: 30 }}>An Eternal Golden Braid</h1>
          </Col>
          <Col xs={12} md={6}>
            <p>
              <a
                href="https://en.wikipedia.org/wiki/G%C3%B6del,_Escher,_Bach"
                target="_blank"
                rel="noopener noreferrer"
              >
                A book that laid the groundwork for AI, introducing key concepts
                like recursion, self-reference, and complexity, which continue
                to influence the field today.
              </a>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex mt-3">
          <Col xs={12} md={3} className="">
            <p style={{ wordBreak: "break-word" }}>
              39yCqErdfzsYiro78fiGSeLCF92C3KUXUudpWqxkpump
            </p>
            <img src={Image0} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex">
          <Col xs={12} md={6}>
            <p className="mt-4">
              An Eternal Golden Braid is a <b>foundational text in AI,</b>{" "}
              examining recursion, self-reference, and emergent complexity—core
              concepts in advancing machine cognition and understanding beyond
              simple computational tasks.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center d-flex">
          <Col xs={12} md={3} className="mt-4">
            <img src={Image9} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex">
          <Col xs={12} md={6}>
            <p className="mt-4">
              An Eternal Golden Braid bridges logic, art, and music to explore
              self-reference and recursion, <b>concepts vital to AI.</b> It
              challenges the boundaries of machine intelligence, pushing AI
              beyond algorithmic computation toward emergent, human-like
              reasoning.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center d-flex">
          <Col xs={12} md={3} className="mt-4">
            <img src={Image10} style={{ width: "100%" }} />
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center d-flex">
          <Col xs={12} md={6}>
            <p className="mt-4">
              Exploring the intertwining of formal systems, creativity, and
              cognition, Gödel, Escher, Bach offers{" "}
              <b>deep insights into AI, </b>
              focusing on how complexity and self-reference shape the path
              toward machine understanding and intelligent problem-solving.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center d-flex">
          <iframe
            width="100%"
            height={window.innerWidth > 600 ? "500" : "200"}
            src="https://www.youtube.com/embed/15ezpwCHtJs?autoplay=1&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay"
            allowFullScreen
          ></iframe>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image8} style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image6} style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image4} style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image5} style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image3} style={{ width: "100%" }} />
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <img src={Image2} style={{ width: "100%" }} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
